import React, { useState } from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 

import data from "@components/pagePartners/pageForm/data"
import Form from "@components/pagePartners/pageForm/Form"
import TemplatePagesThank from "@components/pageThanks/pageThanksGeneral/template"

const StructureMainPage = ({ location }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  //Estado para abrir el modal de confirmacion
  const [confirm, setConfirm] = useState(false)
  const onClickConfirm = () => {
    setConfirm(true)

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  return (
    <div className="fnd">
      {/* Etiquetas H1 y H2 del Slider */}
      <div style={{ display: "none" }}>
        <h1>¡Estás a un paso de convertirte en nuestro partner!</h1>
      </div>
      <section>
        <BannerCookies />
        
        <section>
          <div>
            <Header
              path="/programa-partners-beex/"
              location={location}
              bannerCTA={false}
              windowsWidth={windowsWidth}
            />
          </div>

          <div className="container">
            {confirm ? (
              <div>
                <TemplatePagesThank location={location} />
              </div>
            ) : (
              <div>
                <Form
                  data={data}
                  location={location}
                  confirm={confirm}
                  onClickConfirm={onClickConfirm}
                />
              </div>
            )}
             <Footer data={data.footer} location={location} />
            <FooterMobile data={data.footer} location={location} />
          </div>
        </section>
      </section>
    </div>
  )
}

export default StructureMainPage
